module directives {
    export module applicationcore {
        interface ILoadingIndecatorScope extends ng.IScope {
        }
        export class loadingIndecatorDirective implements ng.IDirective {
            public restrict: 'E';
            public template = `
                        <uib-progressbar class="progress-striped active" value="100" type="info"></uib-progressbar>
                        `;
            public scope = {}

            constructor() {
            }

            link = ($scope: ILoadingIndecatorScope, $element: ng.IAugmentedJQuery) => {
                var controller = $element.parent().controller();


            }

            static factory(): ng.IDirectiveFactory {
                const directive = () => new loadingIndecatorDirective();
                directive.$inject = [];

                return directive;
            }
        }

        angular.module("app").directive("gtsLoadingIndecator", loadingIndecatorDirective.factory());
    }
}